.billmain{
    width: 70%;
    min-height: 600px;
    /* border: 1px solid black; */
    position: relative;
    margin-top: 20px;
}
.headerpart{
    width: 100%;
    height: auto;
    display: flex;
}
.headerpart>.left{
    width: 25%;
    height: auto;
 
    display: flex;
    justify-content: center;
    padding-top: 7px;
}
.headerpart>.right{
    width: 75%;
    height: auto;

    padding-left: 20px;
    padding-top: 7px;
}
.billdetail{
    width: 100%;
    display: flex;
   padding: 10px;
border-bottom: 1px solid black;
}
.billno{
    width: 50%;

}
.billdate{
    width: 50%; 
    display: flex;
    justify-content: end;
   
}
.customerpart{
    padding: 10px;
   border-bottom: 1px solid black;
}
.golddetails{
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid black;
}