.side-admin{
    display: flex;
    gap: 9px;
    align-items: center;
    margin: 8px 2px;
    color: black;
    text-decoration: none;
    padding: 4px;
    color: white;
}
.buygold:hover{
    background: #facc15;
}
.side-admin:hover{
    background: #facc15;
    color: white;
}
.jfdoweh .buygold{
    padding: 0px !important;
}
.dfoewh .bgv{
    background-color: #a05b09 !important;
}
.input-add{
    border: 1px solid #502d04;
    padding: 7px;
    border-radius: 4px;
    width:100%
}
.relativeds>span{
    position: relative;
}

.paginationBttns{
    display: flex;
    gap:21px;
    padding: 20px;
    align-items: center;
}
.previous,.next{
    background: #a05b09;
    padding: 7px;
    border-radius: 50px;
    height: 45px;
    text-align: center;
    width: 45px;
}
.previous a,.next a{
    color:white;
    text-decoration: none;
}
.paginationActive{
    background-color: #facc15;
    padding: 5px;
}